import { useEffect, useMemo, useRef } from 'react';
import { Button, Drawer, message, Modal } from 'antd';
import type { DrawerProps } from 'antd';
import { createForm, Field, onFieldInputValueChange } from '@formily/core';
import { createSchemaField } from '@formily/react';
import { Input, FormItem, Select, Form, Radio, NumberPicker } from '@formily/antd-v5';
import { IQuestionItem, QuestionType } from 'src/api/types/exam';
import { cloneDeep, pick } from 'lodash-es';
import { guid, emptyArray } from 'src/utils/tools';
import { schema } from './schema';
import { EditorWrapper } from 'src/components/formily/EditorWrapper';
import { Container } from './components/Container';
import { Choice } from './components/Choice';
import { TextAnswer } from './components/TextAnswer';
import { useBoolean, useMemoizedFn } from 'ahooks';

interface IProps extends DrawerProps {
  data?: IQuestionItem;
  paperId?: string;
  questionLibraryId?: string;
  isScore?: boolean;
  onCancel?: () => void;
  onChange?: (form: Omit<IQuestionItem, 'questionLibraryId' | 'questionId'>) => Promise<void>;
}
interface IFormData {
  questionType: QuestionType;
  body: string;
  options?: { value: string; isAnswer: boolean; id: string }[];
  textAnswer?: string[];
  solution?: string;
  score?: number;
}

const SchemaField = createSchemaField({
  components: {
    Input,
    FormItem,
    Select,
    Container,
    Choice,
    Radio,
    TextAnswer,
    EditorWrapper,
    NumberPicker,
  },
});

export function CreateQuestion(props: IProps) {
  const { open, onCancel, data, paperId, questionLibraryId, isScore, onChange, ...extra } = props;
  const isEdit = !!data?.questionId;

  const oldQuestionType = useRef<QuestionType>(QuestionType.SingleChoice);
  const [modal, contextHolder] = Modal.useModal();
  const [loading, { setTrue, setFalse }] = useBoolean(false);

  const onCloseAndResetForm = useMemoizedFn(() => {
    form.reset();
    onCancel?.();
  });

  const onClose = useMemoizedFn(() => {
    Modal.confirm({
      title: '提示',
      content: '关闭后编辑无法保存，确定关闭吗？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        onCloseAndResetForm?.();
      },
    });
  });

  const initChangeAnswerAndOptions = (question?: string): string[] => {
    if (!question) return [];
    const regBrack = /【(\d+)】/gi;
    const regNum = /(\d+)/gi;
    const op: any[] = [];
    const bracketsArr = question.match(regBrack) ? question.match(regBrack) : [''];
    bracketsArr?.forEach((item) => {
      op.push(Number(item.match(regNum)));
    });
    const result = op.length > 0 ? emptyArray(op.length) : [''];
    return result;
  };

  const form = useMemo(() => {
    return createForm({
      effects: (f) => {
        onFieldInputValueChange('questionType', async (field) => {
          const confirmed = await modal.confirm({
            title: '提示',
            content: '切换题目类型后，选项和答案将被清空',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              // 清空选项
              (f.query('.options').take() as Field).value = [
                { value: '', isAnswer: false, id: guid() },
              ];
              // 清空答案
              (f.query('.textAnswer').take() as Field).value = [''];
            },
            onCancel: () => {
              field.value = oldQuestionType.current;
            },
          });
          if (!confirmed) return;
          const value = field.value;
          oldQuestionType.current = value;
          // 处理options
          const options = f.query('.options').take();
          // 如果是单选、多选、判断、不定项选择，则显示选项
          if (options) {
            if (
              [
                QuestionType.SingleChoice,
                QuestionType.MultipleChoice,
                QuestionType.UnCertainMultiple,
                QuestionType.Judgment,
              ].includes(value)
            ) {
              // 显示选项
              options.display = 'visible';
              // 设置选项的属性
              options?.setComponentProps({
                multiple: [QuestionType.MultipleChoice, QuestionType.UnCertainMultiple].includes(
                  value,
                ),
                isJudgment: value === QuestionType.Judgment,
              });
              if (value === QuestionType.Judgment) {
                // 初始化判断题选项
                (options as Field).value = [
                  { value: '正确', isAnswer: true, id: guid() },
                  { value: '错误', isAnswer: false, id: guid() },
                ];
              }
              // 如果新选项是单选，在此之前是不定项选择，则初始化选中态
              if (
                value === QuestionType.SingleChoice &&
                [QuestionType.MultipleChoice, QuestionType.UnCertainMultiple].includes(
                  oldQuestionType.current,
                ) &&
                (options as Field).value?.find(
                  (option: { id: string; value: string; isAnswer: boolean }) => option.isAnswer,
                )
              ) {
                const findAnswerIndex = (options as Field).value?.findIndex(
                  (option: { id: string; value: string; isAnswer: boolean }) => option.isAnswer,
                );
                const tempOptionValue = cloneDeep((options as Field).value).map((item: any) => ({
                  ...item,
                  isAnswer: false,
                }));
                tempOptionValue[findAnswerIndex].isAnswer = true;
                (options as Field).value = tempOptionValue;
              }
              if (
                value !== QuestionType.Judgment &&
                oldQuestionType.current === QuestionType.Judgment
              ) {
                (options as Field).value = [{ value: '', isAnswer: false, id: guid() }];
              }
            } else {
              options.display = 'none';
            }
          }
          // 处理简单题、填空题
          const textAnswer = f.query('.textAnswer').take();
          const question = f.query('.body').take();
          if (textAnswer) {
            textAnswer.display = [
              QuestionType.FillInBlanks,
              QuestionType.QuestionAndAnswer,
            ].includes(value)
              ? 'visible'
              : 'none';
            if (!textAnswer.visible) return;
            textAnswer.setComponentProps({
              multiple: value === QuestionType.FillInBlanks,
            });
            const currentVal = (textAnswer as Field)?.value;
            (textAnswer as Field).value =
              value === QuestionType.QuestionAndAnswer
                ? currentVal?.[0] || ['']
                : question
                  ? initChangeAnswerAndOptions((question as Field).value)
                  : currentVal?.[0] || [''];
          }
          oldQuestionType.current = value;
        });
        onFieldInputValueChange('body', (field) => {
          const value = field.value;
          const questionType = f.query('.questionType').get('value');
          const textAnswer = f.query('.textAnswer').take() as Field;
          if (questionType !== QuestionType.FillInBlanks || !/【(\d+)】/gi.test(value)) {
            return;
          }
          const oldValue = textAnswer?.value;
          let newValue = initChangeAnswerAndOptions(value);
          if (oldValue?.length === newValue?.length) {
            newValue = cloneDeep(oldValue ?? []);
          } else if (oldValue?.length < newValue?.length) {
            newValue = cloneDeep(oldValue?.concat(newValue?.slice(oldValue?.length))) ?? [];
          } else {
            newValue = cloneDeep(oldValue?.slice(0, newValue?.length)) ?? [];
          }

          textAnswer.value = newValue;
        });
      },
    });
  }, []);

  const onSubmit = async () => {
    if (!paperId && !questionLibraryId) {
      message.error('请选择试卷或者题库');
      return;
    }
    setTrue();
    try {
      const result = (await form.submit()) as IFormData;
      const options = form?.query('.options').take();
      if (options?.display === 'visible') {
        (options as Field).selfErrors = [];
        const isAnswers = result.options?.filter((item: any) => item.isAnswer) ?? [];
        if (result.questionType === QuestionType.MultipleChoice && isAnswers?.length <= 1) {
          message.error('至少选择2个正确答案');
          return;
        }
        if (isAnswers?.length < 1) {
          message.error('至少选择一个正确答案');
          return;
        }
      }

      if (result) {
        const { questionType, options, textAnswer, body, solution } = result;
        const answer = [] as number[];
        options?.forEach((i: { isAnswer: boolean }, index: number) => {
          if (i.isAnswer) {
            answer.push(index);
          }
        });
        const resData = {
          questionType,
          body,
          options: options?.map((item) => item.value),
          answer: answer?.length ? answer : null,
          textAnswer: [QuestionType.FillInBlanks, QuestionType.QuestionAndAnswer].includes(
            questionType,
          )
            ? textAnswer
            : null,
          solution,
        } as IQuestionItem;
        await onChange?.(resData);
        onCloseAndResetForm?.();
      }
    } catch (e) {
      message.error((e as any)?.[0]?.messages?.[0] || '表单校验失败');
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    if (data) {
      form.reset();
      const { questionType, textAnswer } = data;
      // 初始化表单结构
      if ([QuestionType.FillInBlanks, QuestionType.QuestionAndAnswer].includes(questionType)) {
        form.setFieldState('textAnswer', (state) => {
          state.componentProps = {
            multiple: textAnswer?.length && textAnswer?.length > 1,
          };
        });
      } else {
        form.setFieldState('options', (state) => {
          state.componentProps = {
            multiple: [QuestionType.MultipleChoice, QuestionType.UnCertainMultiple].includes(
              questionType,
            ),
            isJudgment: questionType === QuestionType.Judgment,
          };
        });
      }

      form.setValues({
        ...pick(data, ['questionType', 'body', 'solution', 'textAnswer']),
        options: data.options?.map((item, index) => ({
          value: item,
          isAnswer: data.answer?.map(Number)?.includes(index),
          id: guid(),
        })),
        textAnswer: data.textAnswer || [''],
      });
    }
  }, [data]);

  return (
    <Drawer
      open={open}
      closable={false}
      maskClosable={false}
      title={
        <div className="flex justify-between items-center">
          {isEdit ? '编辑题目' : '新增题目'}
          <div className="flex items-center gap-2">
            <Button type="primary" onClick={onSubmit} loading={loading}>
              保存
            </Button>
            <Button onClick={onClose}>取消</Button>
          </div>
        </div>
      }
      onClose={onCloseAndResetForm}
      width={552}
      {...extra}>
      <div className="w-full">
        <Form form={form} layout="vertical">
          <SchemaField schema={schema(isScore)}></SchemaField>
        </Form>
        {contextHolder}
      </div>
    </Drawer>
  );
}
